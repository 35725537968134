<template>
  <v-container v-if="loadedData">
    <perseu-card title="Homologação do Terapeuta">
      <v-form ref="form" slot="content">
        <div class="mb-5">
          <label class="mr-1">Status: </label>
          <v-chip :color="colorStatus" dark>
            {{ data.status }}
          </v-chip>
        </div>
        <v-text-field
          label="Terapeuta *"
          v-model="data.therapistName"
          disabled
          readonly
          :rules="[$rules.required]"
        />
        <v-text-field
          label="Escola de formação *"
          v-model="data.trainingSchoolName"
          disabled
          readonly
          :rules="[$rules.required]"
        />
        <v-text-field
          label="Ano de formação *"
          v-model="data.yearOfFormation"
          disabled
          readonly
          :rules="[$rules.required]"
        />
        <v-textarea
          outlined
          label="Observação do Terapeuta"
          disabled
          readonly
          v-model.trim="data.therapistObservation"
          maxlength="2000"
          counter
        />
        <v-text-field
          label="Cadastro criado em *"
          readonly
          disabled
          v-model="data.createdAt"
          :rules="[$rules.required]"
        />
        <v-text-field
          label="Última atualização *"
          readonly
          disabled
          v-model="data.updatedAt"
          :rules="[$rules.required]"
        />
        <v-textarea
          outlined
          label="Observação da homologação *"
          v-model.trim="data.observation"
          :rules="[$rules.required]"
          maxlength="2000"
          counter
        />
        <image-expansion
          :src="data.bioMagCourseCertification"
          title="Certificado"
          max-width="700"
        />
        <a
          :href="data.bioMagCourseCertification"
          target="_blank"
          rel="noopener noreferrer"
          class="file-link"
          v-if="data.bioMagCourseCertification"
        >
          Ver certificado
        </a>
        <div class="d-flex justify-center">
          <v-switch
            v-model="data.therapistFormedByParMagnetico"
            label="Adicionar Selo Par Magnético (formado no Instituto Par Magnético)"
          />
        </div>
      </v-form>
      <template slot="actions">
        <v-btn color="secondary" outlined @click="back">Voltar</v-btn>
        <v-btn color="success" @click="approve" :disabled="canDisableEdition">
          Aprovar
        </v-btn>
        <v-btn color="error" @click="reject" :disabled="canDisableEdition">
          Rejeitar
        </v-btn>
      </template>
    </perseu-card>
  </v-container>
</template>

<script>
import { approve, reject } from "@/services/therapists-homologation-service";
import { getOne } from "@/services/therapists-homologation-service";
import { pagesNames } from "@/router/pages-names";

export default {
  name: "FormHomologateTerapist",
  components: {
    ImageExpansion: () => import("@/components/shared/ImageExpansion"),
  },
  data: () => ({
    data: {},
    loadedData: false,
  }),
  created() {
    this.load();
  },
  computed: {
    colorStatus() {
      const map = new Map([
        ["Recusado", "red"],
        ["Aceito", "green"],
        ["Pendente de revisão", "orange"],
      ]);
      return map.get(this.data.status) || "";
    },
    canDisableEdition() {
      return this.data.status === "Aceito";
    },
  },
  methods: {
    isValid() {
      return this.$refs.form.validate();
    },
    async load() {
      try {
        this.$store.dispatch("loading/openDialog");
        console.log("getone");
        const { data } = await getOne(this.$route.params.id);
        this.data = data;
        this.loadedData = true;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async back() {
      this.$router.go(-1);
    },
    async approve() {
      if (!this.isValid()) return;

      if (
        confirm(
          `Confirma a homologação do terapeuta ${this.data.therapistName}?`
        )
      ) {
        try {
          this.$store.dispatch("loading/openDialog");
          await approve({
            idHomologation: this.data.id,
            observationRejection: this.data.observation,
            therapistFormedByParMagnetico:
              this.data.therapistFormedByParMagnetico,
          });
          this.$router.push({ name: pagesNames.HOMOLOGATE_THERAPISTS_LIST });
        } catch (e) {
          this.$errorHandler(e);
        } finally {
          this.$store.dispatch("loading/closeDialog");
        }
      }
    },
    async reject() {
      if (!this.isValid()) return;
      if (
        confirm(
          `Confirma a rejeitar a homologação do terapeuta ${this.data.therapistName}?`
        )
      ) {
        try {
          this.$store.dispatch("loading/openDialog");
          await reject({
            idHomologation: this.data.id,
            observationRejection: this.data.observation,
            therapistFormedByParMagnetico:
              this.data.therapistFormedByParMagnetico,
          });
          this.$router.push({ name: pagesNames.HOMOLOGATE_THERAPISTS_LIST });
        } catch (e) {
          this.$errorHandler(e);
        } finally {
          this.$store.dispatch("loading/closeDialog");
        }
      }
    },
  },
};
</script>

<style scoped>
.file-link {
  display: block;
  margin-top: 10px;
  text-align: center;
  color: chocolate;
  text-decoration: underline;
}
</style>
